import React from 'react'
import CustomButton from './button'
import styled from 'styled-components'
import { lightGray, red, black, white, blue, gray } from '../utlis/colors';
import { FormattedMessage } from 'react-intl';

const TextArea = styled.textarea`
  width: 100%;
  background: ${lightGray};
  border: none;
  resize: none;
  padding: 24px 30px 10px 30px;
  color: ${blue};
  font-size: 20px;
  margin-bottom: 30px;

  &::placeholder {
    font-size: 16px;
    color: #797979;
    letter-spacing:1px;
  }
  &:-ms-input-placeholder {
  }
  &::-ms-input-placeholder {
  }
`

const CheckboxWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  color: ${props => (props.error ? red : black)};
  font-size: 16px;
  line-height: 26px;
  &:hover input ~ span {
    background-color: ${gray};
  }
  input:checked ~ span {
    background-color: ${blue};
  }
  input ~ span:after {
    display: none;
  }
  input:checked ~ span:after {
    display: block;
  }
`
const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: red;
`
const Checkmark = styled.span`
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${lightGray};
  :after {
    content: '';
    left: 8px;
    top: 4px;
    width: 5px;
    position: absolute;
    height: 10px;
    border: solid ${white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default class Note extends React.Component {
  onSubmitForm = async (terms, nextStep, handleSubmit) => {
    handleSubmit()
    if (terms) {
      nextStep()
      this.props.savebutton.click()
    }
  }
  render() {
    return (
      <>
        <TextArea
          name="note"
          cols="30"
          value={this.props.info.note}
          placeholder={this.props.locale === 'hr' ? "NAPOMENA" : "NOTE"}
          rows="5"
          onChange={this.props.onChange}
        />

        <CheckboxWrapper error={this.props.error} htmlFor="agree">
          <FormattedMessage id="meetingFormStep3Text" />
          <Input
            type="checkbox"
            id="agree"
            name="agree"
            onClick={() => this.props.toggleTerms()}
            error={this.props.error}
          />
          <Checkmark />
        {/* {this.props.error && <Error>Prihvatite uvjete</Error>} */}
          
        </CheckboxWrapper>

        <CustomButton
          bigger
          white
          bold
          formBtn
          onClick={() =>
            this.onSubmitForm(
              this.props.terms,
              this.props.nextStep,
              this.props.handleSubmit
            )
          }
          label={'Link 2'}
          type="submit"
        >
          {this.props.locale === 'hr' ? "Pošalji" : "Send"}
        </CustomButton>
        
      </>
    )
  }
}
