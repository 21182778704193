import React from 'react'
import CustomButton from './button'
import TextInputGroup from './textinputgroup'
import Dropdown from './dropDown';

function AccommmodationInfo({ onChange, info, handleBlur, onClick, type , locale}) {
  return (
    <>
      <Dropdown
        onChange={onChange}
        value={info.accommodation}
        name="accommodation"
        type="input"
        label={locale === 'hr' ? "Tip smjestaja" : "Accommodation Type"}
        onBlur={handleBlur}
        error={info.error.accommodation}
        options={locale==='hr' ? ['Odaberi', 'Soba', 'Studio', 'Apartman', 'Kuća', 'Kuća s bazenom', 'Hotel', 'Hostel']: ['Choose', 'Room', 'Studio', 'Apartment', 'House', 'House with pool', 'Hotel', 'Hostel'] }

      />
      <TextInputGroup
        onChange={onChange}
        name="city"
        value={info.city}
        type="text"
        label={locale==='hr'? "Grad" : "City"}
        onBlur={handleBlur}
        error={info.error.city}
      />
      <Dropdown
        onChange={onChange}
        name="country"
        value={info.country}
        type="input"
        label={locale==='hr'? "Država" : "Country"}
        onBlur={handleBlur}
        error={info.error.country}
        options={['Odaberi','Hrvatska']}
      />
      <CustomButton
        bigger
        white
        bold
        formBtn
        onClick={() => onClick()}
        label={'Link 2'}
        type={type}
      >
        {locale==='hr' ? 'Sljedeći korak' : 'Next step'}
        </CustomButton>
    </>
  )
}


export default AccommmodationInfo