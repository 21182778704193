import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import { Wrapper } from './baseStyles'
import Paragraph from './paragraph'
import Form from './form'
import { blue, gray } from '../utlis/colors'
import { FormattedMessage } from 'react-intl'


const Dots = styled.div`
  text-align: center;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`
const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${props => (props.active ? blue : gray)};
  border-radius: 100%;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`

const FormWrapper = styled.div`
  width: calc((100% / 12) * 10);
  margin: 0 auto;
  display: flex;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  > div:first-child {
    width: 40%;
    text-align: center;
    padding-right: 15px;
    @media screen and (max-width: 768px) {
      width: 100%;
    
    }

  }
  > div:last-child {
    width: 60%;
    padding-left: 15px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
    }
      ${props => props.isFinished && css`
        width: 100%;
      `}
  }
`
const ExtendedParagraph = styled(Paragraph)`
  width: 287px;
  margin: 0 auto;
`

const steps = 4

class MeetingForm extends Component {
  state = { counter: 0, data: null }
  nextStep = () => {
    if (this.state.counter <= steps - 2) {
      this.setState(state => {
        return { ...state, counter: state.counter + 1 }
      })
      this.setState()
    } else {
      this.setState({ counter: 0 })
  }
  }

  renderDots = counter => {
    let elements = []
    for (let i = 0; i < steps; i++) {
      elements.push(i)
    }
    return elements.map(element => (
      <Dot key={element} active={counter >= element} />
    ))
  }
  render() {
    const { counter } = this.state
    return (
      <Wrapper>
        <FormWrapper isFinished={counter >= 3}>
          {counter < 3 && (
            <div>
              <ExtendedParagraph>
                {
                 counter===0 && <FormattedMessage id="meetingFormTextSlide1" />}
                 {counter===1 && <FormattedMessage id="meetingFormTextSlide2" />}
                 {counter===2 && <FormattedMessage id="meetingFormTextSlide3" />
                }
              </ExtendedParagraph>

              <Dots>{this.renderDots(counter)}</Dots>
            </div>
          )}
          <div>
            <Form
              locale={this.props.locale}
              nextStep={this.nextStep}
              counter={counter}
            />
          </div>
        </FormWrapper>
      </Wrapper>
    )
  }
}

export default MeetingForm
