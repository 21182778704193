import React from 'react'
import CustomButton from './button'
import TextInputGroup from './textinputgroup'

function PersonalInfo({ onChange, info, handleBlur, onClick, type, locale }) {
  return (
    <>
      <TextInputGroup
        onChange={onChange}
        value={info.name}
        name="name"
        type="text"
        label={locale === 'hr' ? "Kontakt osoba" : "Contact person"}
        onBlur={handleBlur}
        error={info.error.name}

      />
      <TextInputGroup
        onChange={onChange}
        name="phone"
        value={info.phone}
        type="text"
        label={locale === 'hr' ? "Kontakt telefon":"Contact phone"}
        onBlur={handleBlur}
        error={info.error.phone}



      />
      <TextInputGroup
        onChange={onChange}
        name="email"
        value={info.email}
        type="email"
        label={locale === 'hr' ?  "Email adresa" :"Email adress"}
        onBlur={handleBlur}
        error={info.error.email}
      />
      <CustomButton
        bigger
        white
        bold
        formBtn
        onClick={() => onClick()}
        type={type}
      >
        {locale==='hr' ? 'Sljedeći korak' : 'Next step'}
        </CustomButton>
    </>
  )
}


export default PersonalInfo