import React from 'react'
import Layout from '../components/layout'
import { withPrefix } from 'gatsby'
import sastanakIcon from '../images/header/dogovori_sastanak.svg'
import MeetingForm from '../components/meetingForm'
import SEO from '../components/seo'

class Meeting extends React.Component {
  render() {
    const {
      location,
      pageContext: { locale },
    } = this.props
    const isHomePage = location.pathname === withPrefix('/')
    return (
      <Layout
        heroImage={sastanakIcon}
        isHomePage={isHomePage}
        title={
          locale === 'hr' ? 'Dogovori sastanak' : 'Book an appointment'
        }
        text={
          locale === 'hr'
            ? 'Zanima te više detalja o Litto partnerstvu? Ispuni formu ispod i saznaj zašto smo tvoj idealan partner za sljedeću turističku sezonu.'
            : 'Would you like to find out more about Litto partnership program? Fill out the form below and we’ll get in touch soon.'
        }
        locale={locale}
        isMeeting={true}
      >
				<SEO
					title={
						locale === 'hr'
							? 'Dogovori sastanak | Postani partner iznajmljivač | Litto za Partnere'
							: 'Book a meeting | Become a partner renter | Litto For Partners'
					}
					description={
						locale === 'hr'
							? 'Zanima te više detalja o Litto partnerstvu? Ispuni formu na ovoj stranici i saznaj zašto smo tvoj idealan partner za sljedeću turističku sezonu.'
							: 'Would you like to find out more about Litto partnership? Fill out the form on this page and figure out why we’re your ideal partner for the next season.'
					}
					keywords={['Litto']}
					lang={locale}
				/>

        <MeetingForm locale={locale} />
      </Layout>
    )
  }
}

export default Meeting
