import React, { Component } from 'react'
import PersonalInfo from './personalInfo'
import AccommodationInfo from './accommodationInfo'
import Note from './note'
import ThankYou from './thankyou'
import addToMailChimp from 'gatsby-plugin-mailchimp'

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

export default class Form extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    accommodation: '',
    city: '',
    country: '',
    note: '',
    terms: false,
    error: {
      name: null,
      phone: null,
      email: null,
      accommodation: null,
      city: null,
      country: null,
      terms: null,
    },
    submitted: false
  }
  handleSubmit = e => {
    const { counter } = this.props
    const {
      name,
      phone,
      email,
      accommodation,
      city,
      country,
      terms,
    } = this.state
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const p = /^(\+\d{1,3}[- ]?)?\d{10}|(\+\d{1,3}[- ]?)?\d{9}$/
    if (counter === 0) {
      name === '' &&
        this.setState(state => ({ error: { ...state.error, name: true } }))
      phone === '' &&
        this.setState(state => ({ error: { ...state.error, phone: true } }))
      p.test(String(phone).toLowerCase())===false && 
        this.setState(state=>({error: {...state.error, phone: true}}))
      email === '' &&
        this.setState(state => ({ error: { ...state.error, email: true } }))
      re.test(String(email).toLowerCase()) === false &&
        this.setState(state => ({ error: { ...state.error, email: true } }))

      if (
        name !== '' &&
        phone !== '' &&
        email !== '' &&
        re.test(String(email).toLowerCase()) &&
        p.test(String(phone).toLowerCase())

      ) {
        this.props.nextStep()
      }
    } else if (counter === 1) {
      accommodation === 'Odaberi' ||
        (accommodation === '' &&
          this.setState(state => ({
            error: { ...state.error, accommodation: true },
          })))
      city === 'Odaberi' ||
        (city === '' &&
          this.setState(state => ({ error: { ...state.error, city: true } })))
      country === 'Odaberi' ||
        (country === '' &&
          this.setState(state => ({
            error: { ...state.error, country: true },
          })))

      if (
        accommodation !== 'Odaberi' &&
        accommodation !== '' &&
        city !== 'Odaberi' &&
        city !== '' &&
        country !== 'Odaberi' &&
        country !== ''
      ) {
        this.props.nextStep()
      }
    } else if (counter === 2) {
      if (!terms) {
        this.setState(state => ({ error: { ...state.error, terms: true } }))
      }
    }
  }

  resetAll = () => {
    this.setState({
      name: '',
      phone: '',
      email: '',
      accommodation: '',
      city: '',
      country: '',
      note: '',
      error: {
        name: null,
        phone: null,
        email: null,
        accommodation: null,
        city: null,
        country: null,
        terms: null,
      },
      terms: false,
      loading: false,
    })
    this.props.nextStep(0)
  }


  onChange = e => this.setState({ [e.target.name]: e.target.value })

  handleBlur = e => {
    if (e.target.value === '') {
      this.setState({ error: { ...this.state.error, [e.target.name]: true } })
    } else {
      delete this.state.error[e.target.name]
      this.setState({ state: { ...this.state } })
    }
  }

  onSubmit =async e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "form", ...this.state })
    })
      .then(() => this.setState({submitted: true}))
      .catch(error => alert(error));

    e.preventDefault();
    
    
  }

  render() {
    const { counter } = this.props
    const {locale} = this.props
    return (
      <>
        <form name="form" data-netlify="true" onSubmit={this.onSubmit} >

          <input hidden={true} type="hidden" name="form-name" value="form" />
          <input hidden={true} name="name" value={this.state.name} />
          <input hidden={true} name="phone" value={this.state.phone} />
          <input hidden={true} name="city" value={this.state.city} />
          <input hidden={true} name="country" value={this.state.country} />
          <input hidden={true} name="accommodation" value={this.state.accommodation} />
          <input hidden={true} name="email" value={this.state.email} />
          <input hidden={true} name="note" value={this.state.note} />
          <input hidden={true} name="terms" value={this.state.terms} />

          <button hidden={true} ref="submitForm" type="submit">
            SUBMIT
          </button>

          {counter === 0 && (
            <PersonalInfo
              info={this.state}
              onChange={this.onChange}
              handleBlur={this.handleBlur}
              onClick={this.handleSubmit}
              type="button"
              locale={locale}
            />
          )}
          {counter === 1 && (
            <AccommodationInfo
              info={this.state}
              onChange={this.onChange}
              handleBlur={this.handleBlur}
              onClick={this.handleSubmit}
              type="button"
              locale={locale}

            />
          )}

          {counter === 2 && (
            <>
              <Note
                info={this.state}
                onChange={this.onChange}
                handleBlur={this.handleBlur}
                nextStep={this.props.nextStep}
                error={this.state.error.terms}
                toggleTerms={() => this.setState({ terms: !this.state.terms })}
                loading={this.state.loading}
                terms={this.state.terms}
                handleSubmit={this.handleSubmit}
                savebutton={this.refs.submitForm}
                locale={locale}

              />
            </>
          )}
          {counter === 3 && <ThankYou onClick={this.resetAll} />}
        </form>
      </>
    )
  }
}
