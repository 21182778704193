import React from 'react'
import CustomButton from './button'
import Paragraph from './paragraph';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components'



function ThankYou({ onChange, info, handleBlur, onClick, type }) {
  return (
    <>
      <Paragraph large color="black" className="success" style={{ fontSize: "40px",marginBottom: '30px', width: "100%" }}>
        <FormattedMessage id="meetingFormThankYouNote"/>
      </Paragraph>
      <CustomButton
        bigger
        white
        bold
        onClick={() => onClick(0)}
        label={'Link 2'}
        type={type}
      >
        <FormattedMessage id="meetingFormSendAnotherButtonText"/>
        </CustomButton>
    </>
  )
}


export default ThankYou