import React from 'react'
import styled from 'styled-components'
import { red, blue, lightGray } from '../utlis/colors'

const FormGroup = styled.div`
  width:100%;
  margin-bottom:30px;
  position:relative;
  @media screen and (max-width: 560px) {
    margin-bottom:20px;
  }
  &::after{
    content:"";
    position:absolute;
    left:0;
    bottom:0;
    background-color:${red};
    height:2px;
    width: ${props => (props.error ? '100%' : '0%')};
    transition: ${props => (props.error ? 'all 0.2s ease' : 'all 0.2s ease')};
  }
`
const Label = styled.label`
  position:absolute;
  left:30px;
  top:50%;
  margin-top:-5px;
  font-size:14px;
  color: #797979;
  letter-spacing:1px;
  pointer-events: none;
  text-transform:uppercase;
  transition:all ease 0.2s;
`
const Select = styled.select`
  width:100%;
  padding:34px 30px 10px 30px;
  color:${blue};
  font-size:20px;
  background-color:${lightGray};
  box-shadow:none;
  border:none;
  appearance:none;
  @media screen and (max-width: 560px) {
    padding:24px 30px 5px 30px;
  }
  &:focus + label,
  select.not-empty + label,
  &:valid + label{
    top:30%;
    transition:all ease 0.2s;
  }
`

const Error = styled.p`
  color:${red};
  margin:0;
  left:30px;
  position:absolute;
  bottom:-20px;
  font-size:12px;
  `
class Dropdown extends React.Component {

  render() {
    const { name, label, onChange, value, onBlur, error, options } = this.props
    return (
      <FormGroup error={error}>
        <Select
          onChange={onChange}
          onBlur={onBlur}
          className={value === "" ? "is-empty" : "not-empty"}
          value={value}
          name={name} id="">
          {
            options.map((option, index) => (
              <option key={index}>{option}</option>
            ))
          }
        </Select>
        <Label
          error={error}
          htmlFor={name}>{label}
        </Label>
        {error && <Error>Provjerite ovo polje</Error>}
      </FormGroup>
    )
  }
}



export default Dropdown;

